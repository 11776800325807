import styled from 'styled-components'
import {
  border,
  color,
  compose,
  flexbox,
  layout,
  position,
  space,
  system,
  typography
} from 'styled-system'

const Box = styled('div')(
  compose(space, border, color, layout, position, flexbox, typography),
  system({
    cursor: true
  })
)

export default Box
