import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Metadata } from 'ui/acro/typography'
import { useModal_deprecated, useGetCountry } from 'hooks'

import PrivacyModal from './PrivacyModal'
import TermsModal from './TermsModal'

const ModalLink = styled('span')({
  cursor: 'pointer',
  fontWeight: 'bold',
  textDecoration: 'underline',
  color: '#00E'
})

const Disclaimer = ({ actionText, Wrapper, ...rest }) => {
  const terms = useModal_deprecated()
  const privacy = useModal_deprecated()
  const country = useGetCountry()

  return (
    <Fragment>
      <Wrapper {...rest}>
        By clicking &quot;{actionText},&quot; or using one of the sign up
        methods below, you agree to our{' '}
        <ModalLink onClick={terms.open} id='terms'>
          Terms of Service
        </ModalLink>
        , and{' '}
        <ModalLink onClick={privacy.open} id='privacy_policy'>
          Privacy Policy
        </ModalLink>
      </Wrapper>

      {terms.active && <TermsModal useModal={terms} country={country} />}
      {privacy.active && <PrivacyModal useModal={privacy} />}
    </Fragment>
  )
}

Disclaimer.defaultProps = {
  Wrapper: Metadata
}

Disclaimer.propTypes = {
  actionText: PropTypes.string.isRequired,
  Wrapper: PropTypes.any,
  country: PropTypes.string.isRequired
}

export default Disclaimer
