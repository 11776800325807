import styled from 'styled-components'

import Box from '../../elements/Box'

const CarouselContainer = styled(Box)`
  position: relative;
  max-width: 100vw;
  
  & .swiper-slide {
    height: ${props => props.autoHeight && 'auto'};
  }

  & ${props => props.paginationClass || '.swiper-pagination-custom'} {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 16px;

    .swiper-pagination-bullet {
      transition: all .25s ease-in-out;
      background: ${props => props.theme.colors.grey4};
      width: 8px;
      height: 8px;
      &.swiper-pagination-bullet-active {
        background: ${props => props.theme.colors.primary};
        width: 8px;
        height: 8px;
      }
    }      
  }
`

export default CarouselContainer
